import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack';
import { useAuth } from './auth';
import { postAPI } from './utils/functions';

export const LoginDiscord = () => {

	const navigate = useNavigate()
	const auth = useAuth()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const client_id = process.env.REACT_APP_CLIENT_ID;
	const client_secret = process.env.REACT_APP_CLIENT_SECRET;
	const scopes = process.env.REACT_APP_SCOPES;
	const redirect_uri = process.env.REACT_APP_DISCORD_RED_URI_LOGIN;

	useEffect(() => {
		var activeLink = document.querySelector('.active-link');
		activeLink ? activeLink.classList.remove('active-link') : activeLink = null;

		/* Check if link is OK and get token*/
		if (window.location.search) {
			const fragment = new URLSearchParams(window.location.search);
			const code = fragment.get('code')
			if (!code) {
				navigate('/login', {replace: true});
				return;
			}

			fetch(`https://discord.com/api/oauth2/token`, {

				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				},
				body: new URLSearchParams({
					client_id: client_id,
					client_secret: client_secret,
					code: code,
					grant_type: 'authorization_code',
					redirect_uri: redirect_uri,
					scope: scopes
				}),

			}).then(res => res.json().then(dat => {
				if (dat.error) { navigate('/login', {replace: true}) }
				fetch(`https://discord.com/api/users/@me`, {

					method: 'GET',
					headers: {
						"Content-type": "application/json",
						"Authorization": `${dat.token_type} ${dat.access_token}`
					}

				}).then(re => re.json().then(async data => {
					let emailExists = await requestEmailExists(data.email)
					if (!emailExists) {
						navigate('/login', {replace: true})
						enqueueSnackbar("Aucun compte SkillUpNow n'est associé à ce compte Discord. Crées-en un !", { variant: 'error' })
					} else if (emailExists) {
						// Email exists and discord is connected
						requestLogin(data.email)
					}
				})).catch((erro) => { 
					if (erro.request) {
						console.warn('Error while request : ', erro.request, 'Complete error : ', erro);
					} else if (erro.response) {
						console.warn('Error while response : ', erro.response, 'Complete error : ', erro);
					} else {
						console.warn('Error while setting up request : ', erro);
					}
				})
			})).catch((err) => { 
				if (err.request) {
					console.warn('Error while request : ', err.request, 'Complete error : ', err);
				} else if (err.response) {
					console.warn('Error while response : ', err.response, 'Complete error : ', err);
				} else {
					console.warn('Error while setting up request : ', err);
				}
			})
		} else { navigate('/login', {replace: true}) }

	}, [])

	const requestLogin = async (email) => {
		await postAPI(`/discordLogin`, {
			email: email
		}).then((dat) => {
			if (dat.error) {
				if (dat.error === 'INVALID_EMAIL') {
					// Incorrect email
					navigate('/login', {replace: true})
					enqueueSnackbar("Aucun compte SkillUpNow n'est associé à ce compte Discord. Crées-en un !", { variant: 'error' })
				}
				return;
			} else {
				dat = dat[0]
				setNewRefreshToken(dat)
				auth.login({
					id: dat.id, 
					pseudo: dat.pseudo, 
					image: dat.image,
					coach: dat.coach
				})
				fetch(`https://api.skillupnow.xyz/enableAccount`, {

					method: 'POST',
					mode: 'cors',
					headers: {
						"Content-Type": "application/json",
						"Authorization": "Bearer $2b$10$s0TNOUyxcDxJ4PAKO/Ogpe"
					},
					body: JSON.stringify({
						id: dat.id
					})

				}).then(resp => resp.json().then(data => {
					//setNewRefreshToken(da)
					if (data.changedRows === 1) enqueueSnackbar('Votre compte a été réactivé !', { variant: "success" })
				})).catch((err) => { 
					if (err.request) {
						console.warn('Error while request : ', err.request, 'Complete error : ', err);
					} else if (err.response) {
						console.warn('Error while response : ', err.response, 'Complete error : ', err);
					} else {
						console.warn('Error while setting up request : ', err);
					}
				})
				navigate('/home');
				return;
			}
		})
	}

	const setNewRefreshToken = (user) => {
		if (!user.discord_account) return;
		fetch(`https://discord.com/api/oauth2/token`, {

			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			body: new URLSearchParams({
				client_id: client_id,
				client_secret: client_secret,
				grant_type: 'refresh_token',
				refresh_token: user.discord_account
			}),

		}).then(res => res.json().then(dat => {
			if (dat.error === 'invalid_grant') {
				console.error('[DISCORD_AUTH] => Invalid refresh token in database');
				return;
			} else {
				fetch(`https://discord.com/api/users/@me`, {

					method: 'GET',
					headers: {
						"Content-type": "application/json",
						"Authorization": `${dat.token_type} ${dat.access_token}`
					}

				}).then(re => re.json().then(async data => {
					await postAPI(`/refreshDiscord`, {
						id: user.id,
						discord_account: dat.refresh_token,
						discord_email: data.email
					}).then(() => {
						console.info('[DISCORD_AUTH] => Token refreshed');
					})
				})).catch((erro) => { 
					if (erro.request) {
						console.warn('Error while request : ', erro.request, 'Complete error : ', erro);
					} else if (erro.response) {
						console.warn('Error while response : ', erro.response, 'Complete error : ', erro);
					} else {
						console.warn('Error while setting up request : ', erro);
					}
				})
				
			}
		})).catch((err) => { 
			if (err.request) {
				console.warn('Error while request : ', err.request, 'Complete error : ', err);
			} else if (err.response) {
				console.warn('Error while response : ', err.response, 'Complete error : ', err);
			} else {
				console.warn('Error while setting up request : ', err);
			}
		})
	}

	const requestEmailExists = async (email) => {
		var ret = false;
		await postAPI(`/emailExists`, {
			email: email
		}).then((dat) => {
			if (dat.length > 0) {
				ret = true;
			}
		})
		return ret;
	}
}