import { enqueueSnackbar } from "notistack";

export const sendNotification = async (receiver, sender, text, linkTo) => {
	await postAPI("/sendNotification", {
		receiver: receiver, 
		sender: sender,
		text: text,
		linkTo: linkTo
	})
}

export const sendMailNotification = async (to, subject, content) => {
	await postAPI("/sendMailNotification", {
		to: to, 
		subject: subject,
		content: content
	})
}

export const sendReview = async (coach, user, note, message) => {
	await postAPI(`/postReview`, {
		coach: coach, 
		user: user,
		note: note,
		message: message
	}).then(() => {
		return enqueueSnackbar("Votre avis a été publié", { variant: 'success' })
	})
}

export const postAPI = async (endpoint, body = {}) => {
	return new Promise(async (resolve, reject) => {
		await fetch(`https://api.skillupnow.xyz${endpoint}`, {

			method: 'POST',
			mode: 'cors',
			headers: {
				"Content-Type": "application/json",
				"Authorization": process.env.REACT_APP_AUTH
			},
			body: JSON.stringify(body)
	
		}).then(response => response.json().then(data => {
			resolve(data);
		})).catch((err) => { 
			if (err.request) {
				console.warn('Error while request : ', err.request, 'Complete error : ', err);
			} else if (err.response) {
				console.warn('Error while response : ', err.response, 'Complete error : ', err);
			} else {
				console.warn('Error while setting up request : ', err);
			}
			reject(err)
		})
	})
}