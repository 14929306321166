import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Checkbox } from './checkbox';
import { Link } from 'react-router-dom'
import { postAPI } from './utils/functions'

export default class Coachs extends React.Component {
	state = {
		coachs: [],
		jeux: [],
		loading: true,
		filters: {
			game: '',
			certified: false,
			name: '',
			min_p: null,
			max_p: null,
			min_n: null,
			max_n: null
		}
	}

	componentDidMount() {
		let activeLink = document.querySelector('.active-link');
		activeLink ? activeLink.classList.remove('active-link') : activeLink = null;
		document.getElementById('coachs').classList.add('active-link')

		postAPI(`/coachs`).then((dat) => {
			if (dat.length >= 0) {
				this.setState({ coachs: dat, loading: false })
			}
		})

		postAPI(`/jeux`).then((dat) => {
			if (dat.length >= 0) {
				this.setState({ jeux: dat, loading: false })
			}
		})
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextState.filters != this.state.filters) {
			postAPI(`/coachs`).then((dat) => {
				if (dat.length >= 0) {
					this.setState({ coachs: Object.values(dat).filter((v) => {
						if (nextState.filters.game === '' && nextState.filters.name === '' && nextState.filters.certified === false) {
							return dat;
						}
						return v.verified >= nextState.filters.certified && v.pseudo.toUpperCase().includes(nextState.filters.name.toUpperCase()) && v.nom.toUpperCase().includes(nextState.filters.game.toUpperCase())
					}), loading: false })
				}
			})

			return true;
		}

		if (nextState !== this.state) {
			return true;
		} else return false;
	}

	render() {
		const { coachs, jeux, filters, loading } = this.state
		//console.log(Object.values(filters).map(f => f));

		if(loading) { 
			// RENDER LOADING
			return <div className='w-full flex justify-center'><CircularProgress color="success" /></div> 
		}

		return ( 
			<div className='w-full items-center'>

				{/* JEUX */}
				<div className='bg-[#1B1B1B] w-full flex h-[50px] items-center px-[150px] p-4 box-content flex-nowrap'>
					{jeux.map((v, i) => {
						var imd = null;
						var im;
						if (v.image_jeux) {
							try {
								im = require(`../assets/jeux/${v.image_jeux}`);
							} catch (err) {
								if (err.code === 'MODULE_NOT_FOUND') {
									console.log('module not found ' + v.image_jeux);
									return null;
								}
							} finally {
								if (im) {
									imd = <img className={"object-cover aspect-square box-content p-3.5 hover:bg-[#0E0E0F]/50 rounded ease-in-out duration-100 transition-all hover:cursor-pointer"} width={35} height={35} src={im} />
								}
							}
						}
						return <div className='flex items-center justify-center px-2' key={i} onClick={(e) => {
								this.setState((prevState) => {
									return {
										filters: {
											...prevState.filters,
											game: v.nom
										}
									}
								})
							}}>
							{imd}
						</div>
					})}
				</div>

				{/* FILTERS */}
				<div className='mx-[150px] mt-4 border-2 border-white/10 rounded p-4 bg-[#1B1B1B] flex flex-col min-w-fit'>
					<h3 className='opacity-70'>Appliquer des filtres de recherche pour vous permettre de trouver le coach qui vous correspond.</h3>
					<div className='mt-2 flex items-baseline justify-between min-w-fit flex-wrap'>
						<div className='flex flex-col gap-1 p-4'>
							<h3 className='text-lg'>Nom du coach</h3>
							<div className='input-container-small max-w-fit min-w-fit'>
								<input className='m-0' value={filters.name} placeholder='Rechercher' type="text" name="search_coach" id="search_coach" onChange={(e) => {
									this.setState((prev) => {
										return {
											filters: {
												...prev.filters,
												name: e.target.value
											}
										}
									})
								}} />
							</div>
							<div className='flex items-center gap-2 w-fit mt-2'>
								<Checkbox checked={filters.certified} onChange={(e) => {
									this.setState((prev) => {
										return {
											filters: {
												...prev.filters,
												certified: e.target.checked
											}
										}
									})
								}} />
								<h4 className='whitespace-nowrap overflow-hidden'>Coach vérifié</h4>
							</div>
						</div>

						<div className='flex flex-col gap-1 p-4'>
						<h3 className='text-lg'>Jeux</h3>
							<div className='input-container-small max-w-fit min-w-fit'>
								<input className='m-0' value={filters.game} placeholder='Rechercher' type="text" name="search_game" id="search_game" onChange={(e) => {
									this.setState((prev) => {
										return {
											filters: {
												...prev.filters,
												game: e.target.value
											}
										}
									})
								}} />
							</div>
						</div>

						<div className='flex flex-col gap-1 min-w-fit p-4'>
							<h3 className='text-lg'>Prix</h3>
							<div className='flex items-center flex-wrap gap-2'>
								<div className='input-container-small max-w-fit min-w-fit flex-nowrap gap-1'>
									<input className='m-0' value={filters.min_p} placeholder='Min' type="text" name="max_prix" id="max_prix" size={5} onChange={(e) => {
										e.preventDefault()
										if (isNaN(e.target.value)) {
											this.setState((prev) => {
												return {
														filters: {
														...prev.filters,
														min_p: null
													}
												}
											})
										}
										this.setState((prev) => {
											if (e.target.value.length === 0) return {
												filters: {
													...prev.filters,
													min_p: null
												}
											}
											if (+e.target.value) {
												return {
													filters: {
														...prev.filters,
														min_p: +e.target.value
													}
												}
											} else {
												return {
													filters: {
														...prev.filters,
														min_p: null
													}
												}
											}
											
										})
									}} />
									€
								</div>
								à
								<div className='input-container-small max-w-fit min-w-fit flex-nowrap gap-1'>
									<input className='m-0' value={filters.max_p} placeholder='Max' type="text" name="max_prix" id="max_prix" size={5} onChange={(e) => {
										e.preventDefault()
										if (isNaN(e.target.value)) {
											this.setState((prev) => {
												return {
														filters: {
														...prev.filters,
														max_p: null
													}
												}
											})
										}
										this.setState((prev) => {
											if (e.target.value.length === 0) return {
												filters: {
													...prev.filters,
													max_p: null
												}
											}
											if (+e.target.value) {
												return {
													filters: {
														...prev.filters,
														max_p: +e.target.value
													}
												}
											} else {
												return {
													filters: {
														...prev.filters,
														max_p: null
													}
												}
											}
											
										})
									}} />
									€
								</div>
							</div>
						</div>

						<div className='flex flex-col gap-1 min-w-fit p-4'>
							<h3 className='text-lg'>Note</h3>
							<div className='flex items-center flex-wrap gap-2'>
								<div className='input-container-small max-w-fit min-w-fit flex-nowrap gap-1'>
									<input className='m-0' value={filters.min_n} placeholder='Min' type="text" name="max_prix" id="max_prix" size={1} onChange={(e) => {
										e.preventDefault()
										if (isNaN(e.target.value)) {
											this.setState((prev) => {
												return {
														filters: {
														...prev.filters,
														min_n: null
													}
												}
											})
										}
										this.setState((prev) => {
											if (e.target.value.length === 0) return {
												filters: {
													...prev.filters,
													min_n: null
												}
											}
											if (+e.target.value) {
												return {
													filters: {
														...prev.filters,
														min_n: +e.target.value
													}
												}
											} else {
												return {
													filters: {
														...prev.filters,
														min_n: null
													}
												}
											}
											
										})
									}} />
									<svg height="25px" width="25px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 53.87 53.87" xmlSpace="preserve" fill="#ffffff" stroke="#ffffff" strokeWidth="0.00053867"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon style={{fill: '#EFCE4A'}} points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "></polygon> </g></svg>
								</div>
								à
								<div className='input-container-small max-w-fit min-w-fit flex-nowrap gap-1'>
									<input className='m-0' value={filters.max_n} placeholder='Max' type="text" name="max_prix" id="max_prix" size={1} onChange={(e) => {
										e.preventDefault()
										if (isNaN(e.target.value)) {
											this.setState((prev) => {
												return {
														filters: {
														...prev.filters,
														max_n: null
													}
												}
											})
										}
										this.setState((prev) => {
											if (e.target.value.length === 0) return {
												filters: {
													...prev.filters,
													max_n: null
												}
											}
											if (+e.target.value) {
												return {
													filters: {
														...prev.filters,
														max_n: +e.target.value
													}
												}
											} else {
												return {
													filters: {
														...prev.filters,
														max_n: null
													}
												}
											}
											
										})
									}} />
									<svg height="25px" width="25px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 53.87 53.87" xmlSpace="preserve" fill="#ffffff" stroke="#ffffff" strokeWidth="0.00053867"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon style={{fill: '#EFCE4A'}} points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "></polygon> </g></svg>
								</div>
							</div>
						</div>

					</div>
					<div className='w-full flex justify-end'>
						<button className='button-secondary w-fit' onClick={(e) => {
							var temp0 = {
								game: '',
								certified: false,
								name: '',
								min_p: null,
								max_p: null,
								min_n: null,
								max_n: null
							}
							this.setState({ filters: temp0 })
						}}>Supprimer les filtres</button>
					</div>
				</div>

				{/* COACHS */}
				<div className='flex gap-36 flex-wrap mx-[150px] mt-20'>
					{coachs.map((v, i) => {
						/* AVATAR */
						var imd = <div className="bg-black/50 bg-[url('/src/assets/avatar-svgrepo-com.svg')] rounded bg-no-repeat h-[250px] w-[250px]"></div>;
						var im;
						if (v.image) {
							try {
								im = require(`../../public/upload/members/${v.image}`);
							} catch (err) {
								if (err.code === 'MODULE_NOT_FOUND') {
									console.log('module not found ' + v.image);
									return null;
								}
							} finally {
								if (im) {
									imd = <img className={"object-cover aspect-square rounded"} width={250} height={250} src={im} />
								}
							}
						}
						
						/* GAME-BANNER */
						var imb = <div className="bg-black/50 rounded h-[410px] w-[290px]"></div>;
						var im2;
						if (v.banner_jeux) {
							try {
								im2 = require(`../assets/jeux/${v.banner_jeux}`);
							} catch (err) {
								if (err.code === 'MODULE_NOT_FOUND') {
									console.log('module not found ' + v.banner_jeux);
									return null;
								}
							} finally {
								if (im2) {
									imb = <img className={"object-cover rounded-md"} width={290} height={410} src={im2} alt={im2}/>
								}
							}
						}

						var languages = v.language.split(',').map((v) => (`${v}.png`.trim()))

						return <div className='relative' key={i}>

								<Link to={`/coachs/${v.pseudo}`}><div className={`shadow-lg w-[250px] h-[395px] rounded-md bg-[#1B1B1B] p-4 flex flex-col box-content gap-1 z-10 relative -mt-8 -ml-6 border-2 border-white/5 hover:-mr-2 hover:-mt-6 hover:-ml-4 hover:cursor-pointer duration-100 ease-in-out`}>
									<div>{imd}</div>
									<div className='flex items-center'>
										<h4 className='font-bold font-sf-pro-e text-xl'>{v.pseudo}</h4>
										{(v.verified) ? <div className='flex items-center scale-75 -mr-4 pl-px'><VerifiedIcon /> </div> : null}
									</div>

									<div className='flex flex-col'>
										<h4 className='font-bold font-sf-pro-e text-l'>{v.nom}</h4>
										<div className='flex gap-2'>
											{languages.map((h, k) => {return <img key={k} src={require(`../assets/languages/${h}`)} alt={k} className='scale-75 -mx-2' />})}
										</div>
									</div>

									{v.note_coach !== 0 ? 
										<div className='flex gap-1 items-center'>
											<h4 className='text-lg'>{v.note_coach.toFixed(1)}/5.0</h4> 
											<svg height="25px" width="25px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 53.87 53.87" xmlSpace="preserve" fill="#ffffff" stroke="#ffffff" strokeWidth="0.00053867"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon style={{fill: '#EFCE4A'}} points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "></polygon> </g></svg>
										</div>
										: 
										null
									}
										
									<h4>À partir de {v.meilleur_prix.toFixed(2)}€/h</h4>
									
								</div></Link>

								<div className='absolute top-0 -left-2 flex h-[410px] w-[290px]'>{imb}</div>
							</div>
					})}
				</div>


			</div>
			
		)
	}
}
