import { Navigate } from 'react-router-dom';
import { useAuth } from './auth';

import { useSnackbar } from 'notistack'
import { useEffect } from 'react';

const RequireAuth = ({ children }) => {

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const auth = useAuth();

	if (!auth.user.id) {
		enqueueSnackbar('Vous devez vous connecter pour continuer')
		return <Navigate to='/login' />
	}

	return children
    }

export default RequireAuth;