import React, { useEffect, useState } from 'react';
import { useAuth } from './auth';
import { postAPI } from './utils/functions';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

const Notifications = ({ newNotifs }) => {
	const auth = useAuth();
	const [notifications, setnotifications] = useState([]);
	const [loading, setloading] = useState(true);
	const navigate = useNavigate()

	useEffect(() => {
		const fetchNotifications = async () => {
			await postAPI(`/notifications/${auth.user.id}`).then((dat) => {
				setnotifications(dat)
				setloading(false)
			})
		}
		fetchNotifications()
	}, [])

	useEffect(() => {
		newNotifs(Object.values(notifications).filter(n => !n.isRead).length)
	}, [notifications])

	if (loading) {
		return <div className='w-full flex justify-center'><CircularProgress color="success" /></div> 
	}

	if (notifications.length === 0) {
		return <div className='flex items-center justify-center'>Aucune notification pour le moment</div>
	}

	return (
		<div className='flex flex-col gap-2 max-h-96 overflow-scroll'>
			{Object.values(notifications).map((notif, i) => {
				if (notif.isRead) {
					return (
						<div key={notif.notification_id} className='flex flex-col gap-1 border-l-2 border-white/25 p-2 rounded-r cursor-pointer hover:bg-white/10' onClick={() => {
							navigate(`${notif.linkTo}`)
						}}>
							<h3>{notif.text}</h3>
							<h4 className='flex items-center justify-end opacity-75 text-sm'>{moment(notif.date).format('ll')} à {moment(notif.date).format('HH[h]mm')}</h4>
						</div>
					)
				} else {
					return (
						<div key={notif.notification_id} className='flex flex-col gap-1 border-l-2 border-primary/50 p-2 bg-white/5 rounded-r cursor-pointer hover:bg-white/10' onClick={async (e) => {
							navigate(`${notif.linkTo}`)
							await postAPI(`/notification/markAsRead`, {
								notification_id: notif.notification_id
							}).then(() => {
								newNotifs(Object.values(notifications).filter(n => !n.isRead).length-1)
							})
						}}>
							<h3>{notif.text}</h3>
							<h4 className='flex items-center justify-end opacity-75 text-sm'>{moment(notif.date).format('ll')} à {moment(notif.date).format('HH[h]mm')}</h4>
						</div>
					)
				}
			})}
		</div>
	);
}

export default Notifications;