import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from '../auth';
import Progressbar from '../utils/progressbar';
import { postAPI, fetchSeance } from '../utils/functions';

const ProfileCredits = () => {

	const auth = useAuth();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const navigate = useNavigate();
	const [credits, setCredits] = useState(null)

	useEffect(() => {
		postAPI(`/member/${auth.user.id}`).then((dat) => {
			if (dat.length > 0) {
				if (dat[0].pseudo === auth.user.pseudo) {
					setUser(dat[0])
					return;
				} else {
					auth.logout()
					navigate('/')
					return;
				}
			} else {
				auth.logout()
				navigate('/')
				return;
			}
		})
	}, [])

	useEffect(() => {
		if (user) {
			postAPI(`/member/credits/${auth.user.id}`).then(async (dat) => {
				let res = Object.values(dat).filter((c) => c.utilisé < c.formule.heures_f )
				setCredits(res);
				setLoading(false);
			})
		}
	}, [user])

	if (loading) return (
		<div className='flex flex-col gap-10 w-full h-full'>
				<div className='leading-[20px]'>
					<h3 className='font-bold font-sf-pro-e text-2xl'>Vos crédits</h3>
					<p className='text-white/75'>Vous pouvez consulter ici vos crédits disponibles</p>
				</div>

				<div className='flex items-center justify-center mt-8'><CircularProgress color="success" /></div>
		</div>
	)

	if (credits && credits.length > 0) {
		return (
			<div className='flex flex-col gap-10 w-full h-full'>
	
					<div className='leading-[20px]'>
						<h3 className='font-bold font-sf-pro-e text-2xl'>Vos crédits</h3>
						<p className='text-white/75'>Vous pouvez consulter ici vos crédits disponibles</p>
					</div>
	
					<div className='flex flex-col gap-4 w-full'>
						{Object.values(credits).map((credit, i) => {
							return (
								<div className='bg-[#1B1B1B] flex gap-2 justify-between p-3 rounded border-2 border-white/10' key={i}>
									{/* Infos */}
									<div className='flex flex-col gap-1 justify-center'>
										<div className='flex gap-2'>
											<h4 className='font-sf-pro-e font-bold'>{credit.coach.pseudo}</h4>
											<h4 className='opacity-75'>•</h4>
											<h4 className='opacity-75'>{credit.formule.titre}</h4>
										</div>
										<div>
											<h4 className='opacity-75'>{credit.formule.description_f}</h4>
										</div>
									</div>
									{/* Progress */}
									<div className='flex flex-wrap gap-3 justify-end mr-2 items-center w-2/3 min-w-[150px]'>
										<div className='flex items-center justify-center gap-2 min-w-[100px]'>
											<Progressbar value={(credit.utilisé/credit.formule.heures_f)*100} />
											<h4 className='font-bold'>{`${credit.utilisé}/${credit.formule.heures_f}`}</h4>
										</div>
										<Link to={`/session/reserve/${credit.id_facture}`} className='button-primary w-fit h-fit'>Utiliser</Link>
									</div>
								</div>
							)
						})}
					</div>
			</div>
		)


	} else {
		return(
			<div className='flex flex-col gap-10 w-full h-full'>

				<div className='leading-[20px]'>
					<h3 className='font-bold font-sf-pro-e text-2xl'>Vos crédits</h3>
					<p className='text-white/75'>Vous pouvez consulter ici vos crédits disponibles</p>
				</div>

				<div className={`border-2 border-white/10 bg-[#1B1B1B] rounded relative p-4`}>
					<div className='text-center'>
						<h3 className='text-xl'>Il semblerait que vous n'ayez aucun crédit à utiliser. <Link to={'/coachs'} className='text-primary'>Trouvez votre coach</Link> dès maintenant !</h3>
					</div>
				</div>

			</div>
		)
	}
}

export default ProfileCredits;