import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar, useSnackbar } from 'notistack'
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from './auth';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import moment from 'moment';
import './panels/calendar.css'

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { sendNotification, postAPI } from './utils/functions';

const Reserve = () => {

	const { facture } = useParams()
	const auth = useAuth();
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const navigate = useNavigate();
	const [credits, setCredits] = useState({})
	const [activeStep, setActiveStep] = useState(0);
	const steps = ["Choisir une date", "Remplir mes informations", "Confirmation"]
	const [selectedDate, setSelectedDate] = useState('');
	const [reservedDate, setReservedDate] = useState(null);
	const calendarEl = useRef()
	const [recapSeance, setRecapSeance] = useState({pseudo: '', rank: '', heures: '', role: '', gamemode: '', faible: '', fort: '', routine: '', attentes: ''});


	const handleNext = () => {
		setActiveStep((prev) => {
			return prev+1
		})
	}

	const handlePrev = () => {
		setActiveStep((prev) => {
			return prev-1
		})
	}

	const save = async () => {
		await postAPI(`/createSeance`, {
			facture: credits.id_facture,
			coach: credits.coach.id,
			member: auth.user.id,
			formule: credits.formule.id_formule,
			date: reservedDate,
			durée: 1,
			status: 'En attente de confirmation',
			recap: JSON.stringify(recapSeance),
			utilisé: credits.utilisé+1
		}).then((data) => {
			if (data.affectedRows) {
				navigate('/profile#seances');
				sendNotification(credits.coach.id, auth.user.id, 'Vous avez une nouvelle demande de réservation', 'profile#demandes')
				enqueueSnackbar(`Votre séance a été réservée ! Elle est en attente d'acceptation par ${credits.coach.pseudo}`, { variant: 'success' })
			}
		})
	}

	useEffect(() => {
		postAPI(`/member/${auth.user.id}`).then((dat) => {
			if (dat.length > 0) {
				if (dat[0].pseudo === auth.user.pseudo) {
					setUser(dat[0])
					return;
				} else {
					auth.logout()
					navigate('/')
					return;
				}
			} else {
				auth.logout()
				navigate('/')
				return;
			}
		})
	}, [])

	useEffect(() => {
		if (user) {
			postAPI(`/member/credits/${auth.user.id}`).then(async (dat) => {
				let r = Object.values(dat).find(c => c.id_facture === facture)
				setCredits(r);
				setLoading(false);
				return;
			})
		}
	}, [user])

	const callCalendar = () => { /* CALENDAR */
		let arr = []
		for (let i = 0; i < 168; i++) {
			arr.push({
				id: i,
				title: ``,
				start: moment().startOf('week').add(i, 'hour').format(),
				end: moment().startOf('week').add(i+1, 'hour').format(),
				allDay: false,
				color: JSON.parse(credits.coach.dispo)[i] === false ? '#ffa2b1' : "rgb(0, 255, 0)",
				display: 'background',
				dispo: JSON.parse(credits.coach.dispo)[i]
			});
		}
		for (let i = 1; i < 5; i++) {
			for (let k = 0; k < 168; k++) {
				arr.push({
					id: i,
					title: ``,
					start: moment().add(i, 'week').startOf('week').add(k, 'hour').format(),
					end: moment().add(i, 'week').startOf('week').add(k+1, 'hour').format(),
					allDay: false,
					color: "rgb(0, 255, 0)",
					display: 'background',
					dispo: true
				});
			}
		}
		let calendar = new Calendar(calendarEl.current, {
			plugins: [ timeGridPlugin ],
			locale: 'fr',
			initialView: 'timeGridWeek',
			slotDuration: '01:00',
			firstDay: '1',
			allDaySlot: false,
			nowIndicator: true,
			eventShortHeight: 15,
			headerToolbar: {
				left: 'title',
				center: '',
				right: 'prev today next'
			},
			buttonText: {
				today: "Aujourd'hui",
				month: 'Mois',
				week: 'Semaine',
				day: 'Jour',
				list: 'Liste'
			},
			eventMouseEnter : () => {
				document.body.style.cursor = 'pointer';
			},
			eventMouseLeave: () => {
				document.body.style.cursor = 'auto';
			},
			eventClick: (info) => {
				if (info.event._def.extendedProps.dispo) {
					let canReserveOn = moment().add(1, 'days').startOf('day')
					if (moment(info.event.start) >= canReserveOn) {
						setSelectedDate(`Date sélectionée : ${moment(info.event.start).format('LLL')} (${moment(info.event.start).calendar()})`)
						setReservedDate(moment.utc(info.event.start).format('YYYY-MM-DD HH:mm:ss'))
					} else {
						enqueueSnackbar(`Vous devez réserver au moins 1 jour à l'avance`, { variant: 'error' })
					}
				} else {
					enqueueSnackbar(`${credits.coach.pseudo} n'est pas disponnible à cette date`, { variant: 'error' })
				}
			},
			eventSources: [arr]
		});
		calendar.render()
	}

	const computeRecap = () => {
		Object.entries(recapSeance).map((entry) => {
			if(!entry[1] && entry[1] === '') {
				let newObj = {
					...recapSeance
				}
				newObj[entry[0]] = 'Non renseigné(e)'
				setRecapSeance(newObj)
			}
		})
	}

	useLayoutEffect(() => {
		if (credits && Object.keys(credits).length !== 0 && credits.constructor === Object && activeStep === 0) {
			if (credits.utilisé >= credits.formule.heures_f) {
				enqueueSnackbar('Toutes les heures ont été utilisées', { variant: 'error' })
				navigate(`/profile#credits`, { replace: true })
				return;
			}
			callCalendar()
		}
	}, [credits, activeStep])

	useEffect(() => { /* EDIT INFORMATIONS */
		if (activeStep === 2) {
			computeRecap()
		} 
	}, [recapSeance])

	useEffect(() => {
		if (activeStep === 2) {
			computeRecap()
		}
	}, [activeStep])

	if (loading || !credits) return (
		<div className='flex flex-col px-[150px] gap-10 w-full h-full'>
				<div className='flex items-center justify-center mt-8'><CircularProgress color="success" /></div>
		</div>
	)

	if (credits && Object.keys(credits).length !== 0 && credits.constructor === Object) {
		return (
			<div className='flex flex-col mt-20 px-[150px] gap-10 w-full h-full'>
	
					<div className='leading-[20px]'>
						<h3 className='font-bold font-sf-pro-e text-2xl'>Réservez une séance avec <Link to={`/coachs/${credits.coach.pseudo}`} className='text-primary'>{credits.coach.pseudo}</Link></h3>
						<p className='text-white/75'>Vous avez acheté {credits.formule.heures_f} crédits à Grimflam ({credits.utilisé} utilisés). Vous avez encore {credits.formule.heures_f-credits.utilisé} heures à placer selon le calendrier du coach. Après cela, {credits.coach.pseudo} n'aura qu'à valider votre séance, ou vous proposer une nouvelle date.</p>
					</div>
	
					<div>
						<Stepper activeStep={activeStep}>
							{steps.map((label) => {
								return (
									<Step key={label}>
										<StepLabel>{label}</StepLabel>
									</Step>
								);
							})}
						</Stepper>
					</div>

					{activeStep === 0 && 
						<div>
							<div id='calendar' ref={calendarEl} className='calendar calendar-week h-auto-'></div>
							<div className='flex justify-between items-center mt-10 mb-10'>
								<h3 className='text-xl'>{selectedDate}</h3>
								{selectedDate !== '' && <div className='button-primary' onClick={handleNext}>Suivant</div>}
							</div>
						</div>
					}

					{activeStep === 1 &&
						<div className='flex flex-col gap-4'>
							<h3 className='w-full opacity-75'>Ces informations sont optionnelles, néanmoins, elles offriront au coach des informations supplémentaires vous concernant pour lui permettre de mieux préparer votre séance.</h3>
							<div className='flex gap-4 items-center flex-wrap'>  {/* INFORMATIONS */}
								<div>
									<h4>Pseudo</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.pseudo} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												pseudo: e.target.value
											};
										})
									}} /></div>
								</div>
								<div>
								<h4>Rang</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.rank} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												rank: e.target.value
											};
										})
									}} /></div>
								</div>
								<div>
									<h4>Rôle(s)</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.role} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												role: e.target.value
											};
										})
									}} /></div>
								</div>
								<div>
									<h4>Heures de jeu</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.heures} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												heures: e.target.value
											};
										})
									}} /></div>
								</div>
								<div>
									<h4>Mode de jeu</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.gamemode} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												gamemode: e.target.value
											};
										})
									}} /></div>
								</div>
								<div>
									<h4>Point faible</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.faible} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												faible: e.target.value
											};
										})
									}} /></div>
								</div>
								<div>
									<h4>Point fort</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.fort} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												fort: e.target.value
											};
										})
									}} /></div>
								</div>
								<div>
									<h4>Routine d'entraînement (exercices / maps d'échauffement / entraînement quotidien)</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.routine} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												routine: e.target.value
											};
										})
									}} /></div>
								</div>
								<div className='w-full'>
									<h4>Qu'attendez-vous de cette séance avec {credits.coach.pseudo} ?</h4>
									<div className={`input-container`}><input type="text" value={recapSeance.attentes} onChange={(e) => {
										setRecapSeance((prev) => {
											return {
												...prev,
												attentes: e.target.value
											};
										})
									}} /></div>
								</div>
							</div>
							<div className='flex justify-between items-center'>
								<div className='button-secondary' onClick={handlePrev}>Précédent</div>
								<div className='button-primary' onClick={handleNext}>Suivant</div>
							</div>
						</div>
					}

					{activeStep === 2 && 
						<div className='flex flex-col gap-10'>
							<div className='text-lg flex flex-col gap-8'>
								<h3 className='text-center text-xl'>Êtes-vous sur de vouloir réserver une séance de coaching le <strong>{selectedDate.substring(18)}</strong> avec <strong>{credits.coach.pseudo}</strong> ?</h3>
								<div className='flex flex-col gap-3'>
									<strong className='underline'>Récapiptulatif des informations</strong>
									<div className='flex flex-col gap-2'>
										<div>Pseudo : {recapSeance.pseudo}</div>
										<div>Rang : {recapSeance.rank}</div>
										<div>Rôle(s) : {recapSeance.role}</div>
										<div>Heures de jeu : {recapSeance.heures}</div>
										<div>Mode de jeu : {recapSeance.gamemode}</div>
										<div>Point faible : {recapSeance.faible}</div>
										<div>Point fort : {recapSeance.fort}</div>
										<div>Routine d'entraînement : {recapSeance.routine}</div>
										<div>Vos attentes : {recapSeance.attentes}</div>
									</div>
								</div>
							</div>
							<div className='flex justify-between items-center'>
								<div className='button-secondary' onClick={handlePrev}>Précédent</div>
								<div className='button-primary' onClick={save}>Confirmer</div>
							</div>
						</div>
					}
			</div>
		)























	} else if (Object.keys(credits).length === 0 && credits.constructor === Object) {
		return navigate('/profile', { replace: true });
	}
}

export default Reserve;