import React, { useEffect } from 'react';
import { useAuth } from './auth';
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack';
import { postAPI } from './utils/functions'

const LinkDiscord = () => {
	
	const auth = useAuth();
	const navigate = useNavigate()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()

	const client_id = process.env.REACT_APP_CLIENT_ID;
	const client_secret = process.env.REACT_APP_CLIENT_SECRET;
	const scopes = process.env.REACT_APP_SCOPES;
	const redirect_uri = process.env.REACT_APP_DISCORD_RED_URI_LINK;

	const setNewRefreshToken = async (id, refr, disc_e) => {
		await postAPI(`/refreshDiscord`, {
			id: id,
			discord_account: refr,
			discord_email: disc_e
		}).then(() => {
			enqueueSnackbar(`Votre compte Discord a été lié avec succès.`, { variant: 'success' })
			navigate('/profile')
		})
	}

	useEffect(() => {
		/* Check if link is OK and get token*/
		if (window.location.search) {
			const fragment = new URLSearchParams(window.location.search);
			const code = fragment.get('code')
			if (!code) {
				navigate('/profile', {replace: true});
				return;
			}
			if (auth.user.discord) {
				enqueueSnackbar(`Un compte Discord est déjà lié à votre compte.`, { variant: 'error' })
				navigate('/profile', {replace: true});
				return;
			}

			postAPI(`/member/${auth.user.id}`).then((dataa) => {
				if (dataa.length > 0) {
					if (dataa[0].pseudo === auth.user.pseudo) {
						fetch(`https://discord.com/api/oauth2/token`, {

							method: 'POST',
							headers: {
								'Content-Type': 'application/x-www-form-urlencoded'
							},
							body: new URLSearchParams({
								client_id: client_id,
								client_secret: client_secret,
								code: code,
								grant_type: 'authorization_code',
								redirect_uri: redirect_uri,
								scope: scopes
							}),

						}).then(res => res.json().then(dat => {
							if (dat.error) { navigate('/profile', {replace: true}) }
							//setNewRefreshToken(auth.user.id, dat.refresh_token);
							fetch(`https://discord.com/api/users/@me`, {

								method: 'GET',
								headers: {
									"Content-type": "application/json",
									"Authorization": `${dat.token_type} ${dat.access_token}`
								}

							}).then(re => re.json().then(async data => {
								// REQUEST EMAIL EXISTS
								await postAPI(`/emailExists`, {
									email: data.email
								}).then((da) => {
									if (da.length > 0) {
										if (da.length === 1 && da[0].email === dataa[0].email) {
											
										} else {
											enqueueSnackbar("Ce compte Discord est déjà lié à un autre compte.", { variant: 'error' })
											navigate('/profile#params')
											return;
										}
									}
								})
								// REQUEST PSEUDO EXISTS
								await postAPI(`/pseudoExists`, {
									pseudo: data.username
								}).then((da) => {
									if (da.length > 0) {
										if (da.length === 1 && da[0].pseudo === dataa[0].pseudo) {
											
										} else {
											enqueueSnackbar("Un compte existe déjà avec le pseudo de ce compte Discord.", { variant: 'error' })
											navigate('/profile#params')
											return;
										}
									}
								})
								// SUCCESS
								setNewRefreshToken(dataa[0].id, dat.refresh_token, data.email)
							})).catch((erro) => {
								if (erro.request) {
									console.warn('Error while request : ', erro.request, 'Complete error : ', erro);
								} else if (erro.response) {
									console.warn('Error while response : ', erro.response, 'Complete error : ', erro);
								} else {
									console.warn('Error while setting up request : ', erro);
								}
							})
						})).catch((err) => { 
							if (err.request) {
								console.warn('Error while request : ', err.request, 'Complete error : ', err);
							} else if (err.response) {
								console.warn('Error while response : ', err.response, 'Complete error : ', err);
							} else {
								console.warn('Error while setting up request : ', err);
							}
						})
					} else {
						auth.logout()
						navigate('/')
						return;
					}
				} else {
					auth.logout()
					navigate('/')
					return;
				}
			})

		} else { 
			enqueueSnackbar("L'URL est incorrect.", { variant: 'error' })
			navigate('/profile', {replace: true})
			return;
		}
		
	}, [])

	return (
		navigate('/profile')
	);
}

export default LinkDiscord;