import React from 'react';
import moment from 'moment';
import { postAPI } from './utils/functions'

import CircularProgress from '@mui/material/CircularProgress';

class Review extends React.Component {
	
	state = {
		avis: [],
		limit: 5,
		isMore: true,
		loading: true
	};

	getData = async () => {
		await postAPI(`/avis/${this.props.coach}`).then((dat) => {
			if (dat.length >= 0) {
				this.setState({ avis: dat.slice(0, this.state.limit), loading: false, isMore: !(dat.length <= this.state.limit) })
			}
		})
	}

	componentDidMount() {
		this.getData();
	}

	loadMore = () => {
		let { limit } = this.state;
		this.setState({ limit: limit+3, loading: true })
		this.getData()
	}
  
	render() {
		const { avis, loading } = this.state;
	
		if(loading) { 
			// RENDER LOADING
			return <div className='w-full flex justify-center m-8'><CircularProgress color="success" /></div> 
		}

		if (avis.length === 0) {
			return <div className='w-full flex justify-center m-8 font-bold text-lg'>Aucun avis</div> 
		}
		
		return (
			<div className='flex flex-col flex-1 gap-4 mb-10'>
				{avis.map((v, i) => {
					return <div className='bg-[#1B1B1B] p-4 rounded flex flex-col gap-4 mt-2 min-w-fit max-w-2xl border-2 border-white/10' key={i}>
							{/* REVIEW HEADER */}
							<div className='flex justify-between w-full items-center'>
								<div className='flex gap-4 items-center'>
									{v.image ? <img className='rounded-full' src={require(`../../public/upload/members/${v.image}`)} height={50} width={50} alt={v.utilisateur} /> : <div className="bg-black/50 bg-[url('/src/assets/avatar-50.svg')] bg-no-repeat h-[50px] w-[50px] border-[1px] rounded-full border-white/40 box-content"></div>}
									<div className='flex flex-col'>
										<h4 className='font-bold font-sf-pro-e'>{v.pseudo}</h4>
										<h4 className='opacity-60'>{moment(v.date).format('LL')}</h4>
									</div>
								</div>
								<div>
									<div className='flex gap-1.5 items-start mt-[-25px]'>
										<h4 className='pt-[2px] tracking-widest'>{v.note}/5</h4>
										<svg height="25px" width="25px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 53.87 53.87" xmlSpace="preserve" fill="#ffffff" stroke="#ffffff" strokeWidth="0.00053867"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon style={{fill: '#EFCE4A'}} points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 "></polygon> </g></svg>
									</div>
								</div>
							</div>

							{/* REVIEW BODY */}
							<div className=''>
								<p className='leading-[24px]'>{v.message}</p>
							</div>
						</div>
				})}
				{
					this.state.isMore && <div className='button-secondary w-fit ml-auto mr-auto' onClick={this.loadMore}>Charger plus d'avis</div>
				}
			</div>
		); 
	}
}

export default Review;
