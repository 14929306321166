import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import moment from 'moment';
import Dialog from '../utils/dialog';

import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from '../auth';
import { sendNotification, postAPI, fetchSeance } from '../utils/functions';

const Demandes = () => {

	const auth = useAuth();
	const navigate = useNavigate();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [seances, setSeances] = useState([])
	const [dialogContent, setDialogContent] = useState(null);

	const [confirmLoading, setconfirmLoading] = useState(false);
	const [cancelLoading, setcancelLoading] = useState(false);

	useEffect(() => {
		postAPI(`/member/${auth.user.id}`).then((dat) => {
			if (dat.length > 0) {
				if (dat[0].pseudo === auth.user.pseudo) {
					setUser(dat[0])
					return;
				} else {
					auth.logout()
					navigate('/')
					return;
				}
			} else {
				auth.logout()
				navigate('/')
				return;
			}
		})
	}, [])

	const fetchData = async () => {
		if (user) {
			await postAPI(`/seances/attente/${auth.user.id}`).then((dat) => {
				setSeances(dat)
				setLoading(false)
				return;
			})
		}
	}

	useLayoutEffect(() => {
		fetchData();
	}, [user])

	const handleCloseDialog = () => {
		setDialogContent(null)
	}

	if (loading) return (
		<div className='flex flex-col gap-10 w-full h-full'>

			<div className='leading-[20px]'>
				<h3 className='font-bold font-sf-pro-e text-2xl'>Demandes de réservation</h3>
				<p className='text-white/75'>Apparaissent ici les demandes de réservation qui vont été envoyées. Vous pouvez accepter, refuser ou proposer une nouvelle date.</p>
			</div>
			<div className='flex items-center justify-center mt-8'><CircularProgress color="success" /></div>
		</div>
	)

	if (seances && seances.length > 0) {
		return (
			<div className='flex flex-col gap-10 w-full h-full'>

				<div className='leading-[20px]'>
					<h3 className='font-bold font-sf-pro-e text-2xl'>Demandes de réservation</h3>
					<p className='text-white/75'>Apparaissent ici les demandes de réservation qui vont été envoyées. Vous pouvez accepter, refuser ou proposer une nouvelle date.</p>
				</div>

				<div className='flex flex-col gap-3'>
					{
						seances.map((demande) => {
							return (
								<div className='flex items-start justify-between gap-3 p-2 bg-[#1B1B1B] border-2 border-white/10 rounded' key={`demande-${demande.id}`}>
									{/* INFOS */}
									<div className='flex flex-col gap-1'>
										<h3 className='font-sf-pro-e font-bold text-lg'>{demande.formule.titre} - {moment(demande.date).format('ll')} à {moment(demande.date).format('HH[h]mm')}</h3>
										<h3 className='opacity-75 font-bold'>{demande.member.pseudo}</h3>
										<br />
										<h3 className='opacity-75 hover:cursor-pointer hover:text-primary text-slate-300 w-fit' onClick={() => {
											setDialogContent(demande)
										}}>Voir le récapitulatif</h3>
									</div>

									{/* BOUTONS */}
									<div className='flex items-center gap-2 flex-wrap justify-end m-2'>
										<div className='button-primary flex items-center' onClick={async (e) => {
											setconfirmLoading(true)
											await postAPI(`/confirmSeance`, {
												id: demande.id
											}).then((dat) => {
												if (dat.affectedRows >= 1) {
													setSeances((prev) => {
														let newObj = prev.filter(s => s.id !== demande.id)
														return newObj
													})
													enqueueSnackbar(`Vous avez accepté la demande de ${demande.member.pseudo}`, { variant: 'success' })
													sendNotification(demande.member.id, demande.coach.id, `La séance n°${demande.id} a été confirmée par ${demande.coach.pseudo}`, "profile#seances")
												}
												setconfirmLoading(false)
											}).catch(() => {
												setconfirmLoading(false)
											})
										}}>{confirmLoading ? <div className='flex items-center gap-1'><CircularProgress color='success' /></div> : <div className='flex items-center gap-1'><CheckIcon />Accepter</div>}</div>
										<div className='button-red flex items-center' onClick={async (e) => {
											setcancelLoading(true)
											await postAPI(`/refuseSeance`, {
												id: demande.id,
												facture: demande.facture
											}).then((dat) => {
												if (dat.affectedRows >= 1) {
													setSeances((prev) => {
														let newObj = prev.filter(s => s.id !== demande.id)
														return newObj
													})
													enqueueSnackbar(`Vous avez refusé la demande de ${demande.member.pseudo}`, { variant: 'error' })
													sendNotification(demande.member.id, demande.coach.id, `La séance n°${demande.id} a été refusée par ${demande.coach.pseudo}`, "profile#seances")
												}
												setconfirmLoading(false)
											}).catch(() => {
												setconfirmLoading(false)
											})
										}}>{cancelLoading ? <div className='flex items-center justify-center'><CircularProgress color='success' className='scale-90' /></div> : <div className='flex items-center gap-1'><CloseIcon />Refuser</div>}</div>
										{/* <div className='button-secondary flex items-center' onClick={(e) => {
											
										}}><div className='flex items-center gap-1'><UpdateRoundedIcon />Reprogrammer</div></div> */}
									</div>

									{
										dialogContent && <Dialog content={dialogContent} handleClose={handleCloseDialog} refreshFunction={fetchData} />
									}

								</div>
							)
						})
					}
				</div>

			</div>
		)
	} else if (seances && seances.length === 0) {
		return (
			<div className='flex flex-col gap-10 w-full h-full'>

				<div className='leading-[20px]'>
					<h3 className='font-bold font-sf-pro-e text-2xl'>Demandes de réservation</h3>
					<p className='text-white/75'>Apparaissent ici les demandes de réservation qui vont été envoyées. Vous pouvez accepter, refuser ou proposer une nouvelle date.</p>
				</div>
				
				<div className={`border-2 border-white/25 bg-[#1B1B1B] rounded relative p-4`}>
					<div className='text-center'>
						<h3 className='text-xl'>Vous n'avez aucune demande en attente</h3>
					</div>
				</div>

			</div>
		)
	}
}

export default Demandes;