import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Checkbox } from './checkbox';
import { useAuth } from './auth';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './checkoutForm';
import { postAPI } from './utils/functions';

const stripePromise = loadStripe("pk_test_51NsQtlJS9ePcKSRh7BSGSzraxpJgILOFuF9jIOIreGybFtgi01JRmo64sHbXh60oclDSr2swF1CZsGSai0r7d542004fPG1dNo")

const CheckoutContainer = ({order}) => {

	const [user, setUser] = useState(null);
	const [activeStep, setActiveStep] = useState(0);
	const steps = ["Informations", "Paiement"]
	const [fname, setFname] = useState('');
	const [lname, setLname] = useState('');
	const [email, setEmail] = useState('');
	const [clientSecret, setclientSecret] = useState(null);

	const auth = useAuth();
	const navigate = useNavigate();

	const handleNext = () => {
		setActiveStep((prev) => {
			return prev+1
		})
	}

	const handlePrev = () => {
		setActiveStep((prev) => {
			return prev-1
		})
	}

	const appearance = {
		theme: 'night',
	};
	const options = {
		clientSecret,
		appearance,
	};

	useEffect(() => {
		postAPI(`/member/${auth.user.id}`).then((dat) => {
			if (dat.length > 0) {
				if (dat[0].pseudo === auth.user.pseudo) {
					setUser(dat[0])
					return;
				} else {
					auth.logout()
					navigate('/')
					return;
				}
			} else {
				auth.logout()
				navigate('/')
				return;
			}
		})
	}, [])

	useEffect(() => {
		if (activeStep === 1) {
			postAPI(`/create-payment-intent`, {
				item: order,
				customer : {
					fname: fname,
					lname: lname,
					email: email,
					id: auth.user.id
				}
			}).then((dat) => {
				setclientSecret(dat.clientSecret)
			})
		}
	}, [activeStep])

	const emailIsCorrect = () => {
		if (email.length > 0) {
			if (email.indexOf("@", 1) !== -1 && email.includes('.', email.indexOf("@", 1)+2) && email.slice(-1) !== '.') {
				return true;
			};
		} 
		if (email.length === 0) {
			return false;
		}
	}

	if (user) { 
		return (
			<div className='w-full flex flex-col gap-10'>

				<div>
					<Stepper activeStep={activeStep}>
						{steps.map((label) => {
							return (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
				</div>

				<div>
					{activeStep === 0 && 
						<div className='flex flex-col gap-8'>
							<h3 className='text-3xl'>Informations personnelles</h3>
							<div className='flex flex-wrap gap-6'>
								<div>
									<h4>Prénom</h4>
									<div className={`input-container`}><input type="text" value={fname} name="fname" id="fname" placeholder='John' onChange={(e) => {
										setFname(e.target.value)
									}} /></div>
								</div>
								<div>
									<h4>Nom</h4>
									<div className={`input-container`}><input type="text" value={lname} name="lname" id="lname" placeholder='Smith' onChange={(e) => {
										setLname(e.target.value)
									}} /></div>
								</div>
								<div>
									<h4>Email</h4>
									<div className={`input-container`}><input type="email" value={email} name="email" id="email" placeholder='example@sun.com' onChange={(e) => {
										setEmail(e.target.value)
									}} /></div>
								</div>
							</div>
							{/* <div className='flex justify-between w-full mt-4 items-center'>
								<div className='flex justify-left gap-2 items-center'>
									<Checkbox className={`scale-110`} checked={rememberInfos} onChange={(e) => {
										setRememberInfos(e.target.checked)
									}}/>
									<p className='opacity-90 text-sm'>Enregistrer ces informations</p>
								</div>
							</div> */}
							<div className='flex justify-end items-center'>
								{fname.length > 1 && lname.length > 1 && emailIsCorrect() ? <div className='button-primary max-w-fit' onClick={handleNext}>Suivant</div> : null}
							</div>
						</div>
					}

					{activeStep === 1 && 
						<div className='flex flex-col gap-8'>
							<h3 className='text-3xl'>Paiement</h3>
							
							<div className='flex justify-between w-full items-center'>
								
								{clientSecret && (
									<Elements options={options} stripe={stripePromise}>
										<CheckoutForm />
									</Elements>
								)}

								{/* <div className='flex justify-left gap-2 items-center'>
									<Checkbox className={`scale-110`} checked={rememberInfos} onChange={(e) => {
										setRememberInfos(e.target.checked)
									}}/>
									<p className='opacity-90 text-sm'>Enregistrer ces informations</p>
								</div> */}
							</div>
							<div className='flex justify-between items-center'>
								<div className='button-primary max-w-fit' onClick={handlePrev}>Précédent</div>
							</div>
						</div>
					}
				</div>
			</div>
		);
	} else {
		return <div className='w-full flex justify-center'><CircularProgress color="success" /></div>
	}
}

export default CheckoutContainer;
