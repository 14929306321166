import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import CheckoutContainer from './checkoutContainer';
import { useAuth } from './auth';
import { postAPI } from './utils/functions';

const Checkout = () => {

	const { formule } = useParams();
	const auth = useAuth();
	const navigate = useNavigate();
	const [order, setOrder] = useState(null);
	const [coach, setCoach] = useState(null);
	const [confirmed, setConfirmed] = useState(false);

	useEffect(() => {
		postAPI(`/formule/${formule}`).then((dat) => {
			if (dat.length >= 0) {
				setOrder(dat[0])
			}
		})
	}, [])

	useEffect(() => {
		if (order) {
			postAPI(`/member/${order.coach}`).then((dat) => {
				if (dat.length >= 0) {
					setCoach(dat[0])
				}
			})
		}
	}, [order])

	useEffect(() => {
		if (coach) {
			const urlParams = new URLSearchParams(document.location.search);
			switch (urlParams.get('redirect_status')) {
				case 'succeeded':
					postAPI(`/confirmOrder`, {
						id: urlParams.get('payment_intent').substring(3),
						member: auth.user.id,
						coach: coach.id,
						formule: order.id_formule
					}).then(() => {
						navigate(`/confirm-order/${urlParams.get('payment_intent').substring(3)}`, { replace: true })
					})
					break;
				default:
					break;
			}
		}
	}, [coach])

	if (order && coach) {
		return (
			<div className='flex mx-36 gap-10 items-baseline'>
				{/* LEFT */}
				<div className='w-full'>
					<CheckoutContainer order={order} />
				</div>
	
				{/* RIGHT - ORDER */}
				<div className='border-2 border-white/10 rounded p-4 bg-[#1B1B1B]'>
					<div className='flex flex-col gap-4'>
						<h3 className='text-xl font-bold font-sf-pro-e'>Résumé de la commande</h3>

						<div className='flex rounded bg-[#0E0E0F] shadow-lg my-4'>
							<img className={"object-cover aspect-square rounded-l"} width={80} height={80} src={require(`../assets/jeux/${order.banner_jeux}`)} alt={'Jeux'}/>
							<div className='p-3 flex flex-col'>
								<h3 className='text-lg'>{order.titre}</h3>
								<p className='opacity-75'>{order.description_f}</p>
							</div>
						</div>

						<h3 className='text-lg'>L'achat de ce pack vous donnera {order.heures_f}h à utiliser quand vous le souhaitez avec {coach.pseudo}</h3>
						{/* <div className='flex gap-2 items-center'>
							<div className='input-container-small max-w-fit'>
								<input type="text" placeholder='Code de réduction' />
							</div>
							<div className='button-secondary'>
								Appliquer
							</div>
						</div> */}
						<div className='w-full border-b-2 border-white/10'></div>
						<div className='w-full flex justify-end'>
							<h3 className='flex gap-3 text-lg'>Vous payez : <p className='text-primary'>{order.prix.toFixed(2)}€ TTC</p></h3>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div className='w-full flex justify-center'><CircularProgress color="success" /></div>
		)
	}
}

export default Checkout;