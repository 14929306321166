import React, { useState, useEffect } from 'react';
import { useAuth } from '../auth';
import VerifiedIcon from '@mui/icons-material/Verified';

const ValidationSeance = ({ user, context }) => {

	const auth = useAuth()
	const [avatar, setAvatar] = useState(<div className="bg-black/100 bg-[url('/src/assets/avatar-200.svg')] rounded bg-no-repeat h-[200px] w-[200px]"></div>);

	useEffect(() => {
		if (user.image) {
			var im;
			try {
				im = require(`../../../public/upload/members/${user.image}`);
			} catch (err) {
				if (err.code === 'MODULE_NOT_FOUND') {
					return;
				}
			} finally {
				if (im) {
					setAvatar(<img className={"object-cover aspect-square rounded max-w-[200px]"} width={200} height={200} src={im} />)
				}
			}
		}
	}, []);

	return (
		<span className='flex flex-col gap-1 h-full w-full'>
			{avatar}
			<div className='flex items-center gap-2'>
				<h2 className='text-xl font-bold font-sf-pro-e whitespace-nowrap'>{user.pseudo + (auth.user.id == user.id ? ' (Vous)' : '')}</h2>
				{
					user.verified && <VerifiedIcon />
				}
			</div>
			{
				context[user.id] ? 
				<div className='flex items-center gap-2'>
					<div className='w-3 h-3 rounded-full bg-primary'></div>
					<h3 className='font-sf-pro-e font-bold'>Prêt</h3>
				</div>
				:
				<div>
					<div className='flex items-center gap-2'>
						<div className='w-3 h-3 rounded-full bg-[#5E98FC]'></div>
						<h3 className='font-sf-pro-e font-bold'>En attente</h3>
					</div>
				</div>
			}
		</span>
	);
};


export default ValidationSeance;
