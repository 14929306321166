import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import AddCommentRoundedIcon from '@mui/icons-material/AddCommentRounded';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import UpdateRoundedIcon from '@mui/icons-material/UpdateRounded';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-quill/dist/quill.snow.css';
import './styles/editor.css'

import { Dashboard } from './panels/dashboard_coach';
import { Parameters } from './panels/parameters';

import { useAuth } from './auth';
import { postAPI } from './utils/functions';
import HistSeances from './panels/hist_seances';
import FuturesSeances from './panels/futures_seances';
import ProfileCredits from './panels/credits';
import Demandes from './panels/demandes';
import Cancel from './panels/cancel';
import Wallet from './panels/wallet';
import { WalletRounded } from '@mui/icons-material';

export const Profile = () => {

	const stylizing = 'flex gap-2 py-3 px-6 bg-[#1B1B1B] items-center rounded box-border';

	const [getPanel, setPanel] = useState(<div className='flex items-center justify-center'><CircularProgress color="success" /></div>);
	const [user, setUser] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const auth = useAuth();
	const navigate = useNavigate();

	const updatePanel = () => {
		var panel = window.location.hash.substring(1);
		var activeSection = document.querySelector('.active-section');
		activeSection ? activeSection.classList.remove('active-section') : activeSection = null;
		if (document.getElementById(panel)) {
			document.getElementById(panel).classList.add('active-section')
		} else {
			setPanel(<div className='flex items-center justify-center'><CircularProgress color="success" /></div>)
			navigate('/profile#params', { replace: true })
			return;
		} 
		
		switch (panel) {
			case 'params':
				setPanel(
					<Parameters />
				)
				break;
			
			case 'seances':
				setPanel(
					<FuturesSeances />
				)
				break;

			case 'hist':
				setPanel(
					<HistSeances />
				)
				break;

			case 'dashboard':
				setPanel(
					<Dashboard />
				)
				break;

			case 'wallet':
				setPanel(
					<Wallet />
				)
				break;

			case 'demandes':
				setPanel(
					<Demandes />
				)
				break;

			case 'cancel':
				setPanel(
					<Cancel />
				)
				break;

			case 'credits':
				setPanel(
					<ProfileCredits />
				)
				break;
		
			default:
				setPanel(
					<div>
						
					</div>
				)
				break;
		}
	}

	useEffect(() => {
		setIsLoading(true)
		if (!window.location.hash) {
			navigate('/profile#params', {replace: true});
		}
		postAPI(`/member/${auth.user.id}`).then((dat) => {
			if (dat.length > 0) {
				if (dat[0].pseudo === auth.user.pseudo) {
					setUser(dat[0])
					setIsLoading(false)
					return;
				} else {
					auth.logout()
					navigate('/')
					return;
				}
			} else {
				auth.logout()
				navigate('/')
				return;
			}
		})
		var activeLink = document.querySelector('.active-link');
		activeLink ? activeLink.classList.remove('active-link') : activeLink = null;

	}, [])

	useEffect(() => {
		if (user) {
			updatePanel();

			window.addEventListener('hashchange', (e) => {
				updatePanel()
			}, false)
		}
	}, [user])

	if (isLoading) return (
		<div className='flex flex-col gap-5 w-full items-center absolute top-1/3 scale-125'>
			<div className='flex items-center justify-center mt-8'><CircularProgress color="success" /></div>
			<h3>Chargement de vos paramètres ...</h3>
		</div>
	)

	return (
		<span className='container flex items-start justify-center h-96 w-full px-[150px] mb-[50px]'>

			<div className='box-border min-w-[305px]'>
				<div className='flex flex-col gap-2 m-6 mr-10 ml-0 box-border'>
					<a href={'#params'} id='params' className={stylizing + ' active-section'}><ManageAccountsRoundedIcon/> Paramètres du compte</a>
					{user && user.coach ? <a href={'#dashboard'} id='dashboard' className={stylizing}><DashboardCustomizeRoundedIcon/> Tableau de bord</a> : null}
					{user && user.coach ? <a href={'#wallet'} id='wallet' className={stylizing}><WalletRounded /> Mon portefeuille</a> : null}
					{user && user.coach ? <a href={'#demandes'} id='demandes' className={stylizing}><AddCommentRoundedIcon/> Demandes de réservation</a> : null}
					{user && user.coach ? <a href={'#cancel'} id='cancel' className={stylizing}><EventBusyRoundedIcon/> Demandes d'annulation</a> : null}
					<a href={'#seances'} id='seances' className={stylizing}><UpdateRoundedIcon/> Séances à venir</a>
					{user && !user.coach ? <a href={'#credits'} id='credits' className={stylizing}><EventAvailableIcon/> Utiliser mes crédits</a> : null}
					<a href={'#hist'} id='hist' className={stylizing}><RestoreRoundedIcon/> Historique des séances</a>
				</div>
			</div>


			<div className='grow border-l-2 border-white/30 min-h-full'>
				<div className='flex flex-col gap-2 m-6 mx-6 mr-0 h-full'>
					{getPanel}
				</div>
			</div>

		</span>
	);
}
