import { createContext, useState, useContext } from "react";

const AuthContext = createContext(undefined);

export const AuthProvider = ({ children }) => {
	
	const [user, setUser] = useState({
		id : localStorage.getItem('id'),
		pseudo: localStorage.getItem('pseudo'),
		image: localStorage.getItem('image'),
		discord: localStorage.getItem('discord') !== 'undefined' ? JSON.parse(localStorage.getItem('discord')) : undefined,
		coach: localStorage.getItem('coach')
	});

	const login = (user) => {
		setUser(user)
		localStorage.setItem('id', user.id)
		localStorage.setItem('pseudo', user.pseudo)
		localStorage.setItem('image', user.image)
		localStorage.setItem('discord', JSON.stringify(user.discord))
		localStorage.setItem('coach', user.coach)
		console.info(`[AUTH] => Logged as ${user.pseudo} (${user.id})`)
	}

	const update = (user) => {
		setUser(user)
		localStorage.setItem('id', user.id)
		localStorage.setItem('pseudo', user.pseudo)
		localStorage.setItem('image', user.image)
		localStorage.setItem('coach', user.coach)
		localStorage.setItem('discord', JSON.stringify(user.discord))
	}

	const logout = () => {
		setUser({id: null, pseudo: null})
		localStorage.removeItem('id')
		localStorage.removeItem('pseudo')
		localStorage.removeItem('image')
		localStorage.removeItem('discord')
		localStorage.removeItem('coach')
		console.info(`[AUTH] => ${user.pseudo} (${user.id}) logged out`)
	}

	return (
		<AuthContext.Provider value={ { user, login, logout, update } }>{children}</AuthContext.Provider>
	)
}

export const useAuth = () => {
	return useContext(AuthContext)
}